<template>
	<v-dialog max-width="450" persistent :value="value" @input="close">
		<v-card
			min-height="290px"
			class=" d-flex flex-column  align-center justify-space-between"
		>
			<BtnClose @click="close" />
			<v-card-title class="mt-2">
				<v-img
					max-width="225px"
					height="auto"
					:src="require('../../assets/logoAppBarPrimary.svg')"
				></v-img>
			</v-card-title>

			<v-container
				v-if="$store.state.lang.lang === 'es'"
				style="max-height: 60vh; overflow:auto;"
			>
				<h2>Terminos y condiciones</h2>
				<hr />
				<h4></h4>
				LEA LOS TÉRMINOS Y CONDICIONES CUIDADOSAMENTE ANTES DE USAR ESTE
				SITIO.
				<p>INTRODUCCIÓN</p>
				<p>
					1. Gracias por usar los servicios de Shuttle Central,
					colectivamente denominado como el "Proveedor". El uso que
					haga de este Sitio está expresamente condicionado a su
					aceptación de los presentes Términos y Condiciones. Al usar
					este sitio, manifiesta su consentimiento con los Términos y
					Condiciones. Shuttle Central se reserva el derecho de
					adicionar o modificar los Términos y Condiciones en
					cualquier momento
				</p>
				<p>DECLARACIONES</p>
				<p>
					2. Usted declara bajo protesta de decir verdad,
					compareciendo por su propio y personal derecho y/o a través
					de su apoderado legal, que es una persona física o moral,
					mayor de edad, hábil, y con los medios suficientes ó con
					capacitad económica para obligarse bajo los presentes
					Términos y Condiciones, así como con interés en adquirir los
					servicios de intermediación y reservación que aquí se
					especifican, y que para ello recurre a Shuttle Central a
					efecto de que funja como intermediaria entre Usted y los
					Proveedores prestadores directos de servicios turísticos
					ofertados en este sitio.
				</p>
				<p>
					3. Usted declara bajo protesta de decir verdad, que reconoce
					que los servicios proporcionados por Shuttle Central,
					consisten UNICA Y EXLUSIVAMENTE EN LA INTERMEDIACIÓN ENTRE
					USTED Y EL PROVEEDOR DIRECTO DE LOS SERVICIOS. Shuttle
					Central declara (a) ser una sociedad mercantil debidamente
					constituida conforme a las leyes mexicanas. (b) Que cuenta
					con autorización de para prestar servicio de transporte por
					tierra, mar y/o aire, así mismo se encuentra registrada ante
					las autoridades Hacendarias con el Registro Federal de
					Contribuyentes número SCE190516DZ1.
				</p>
				<p>4. Que su objeto social consiste en la facultad para:</p>
				<p>
					a. Actuar como intermediaria para la reservación de espacios
					en los medios de transporte y expedir de parte de los
					transportistas y a favor de los turistas los boletos
					correspondientes;
				</p>
				<p>
					b. Servir de intermediario entre los turistas y los
					prestadores de servicios de transportes de cualquier género;
				</p>
				<p>
					c. Reservar a los turistas habitaciones y demás servicios
					conexos; prestar a los turistas servicios de reservación de
					sitios de atracción turística;
				</p>
				<p>
					d. Servir de intermediario entre los demás prestadores de
					servicios turísticos y otras agencias de viajes;
				</p>
				<p>
					e. Proporcionar servicio de información turística y difundir
					el material de propaganda de esta índole que sea de
					distribución gratuita.
				</p>
				<p>
					5. Que para efectos de los presentes Términos y Condiciones,
					únicamente actúa como INTERMEDIARIA entre Usted y los
					Proveedores de dichos servicios turísticos, y que para tal
					objeto, cuenta con la capacidad técnica y administrativa,
					así como con los elementos materiales y humanos necesarios
					para reservar por cuenta y a petición de Usted los servicios
					a los que hace referencia estos Términos y Condiciones.
				</p>
				<p>
					6. Una vez que las reservaciones le sean confirmadas por el
					proveedor conteniendo los servicios turísticos por Usted
					elegidos (de acuerdo a su disponibilidad), se concluirá con
					la prestación del servicio de intermediación por parte de el
					proveedor, ya que dichos servicios turísticos serán
					proporcionados a Usted por los Proveedores de manera
					directa.
				</p>
				<p>USO DEL SITIO</p>
				<p>
					7. Independientemente de su País de procedencia, usted
					declara que tiene la edad legal suficiente para usar este
					sitio, suscribir y obligarse ante los presentes Términos y
					Condiciones, así como para hacerse responsable a las
					obligaciones legales que lo unan con cualquier
					responsabilidad en que incurra por el uso de este Sitio.
				</p>
				<p>
					8. Usted comprende que será económicamente responsable por
					todo el uso que Usted haga de este Sitio y aquellos que lo
					usen utilizando su información para ingresar a él.
				</p>
				<p>
					9. El Proveedor le otorga una licencia limitada,
					intransferible, revocable, para usar el sitio de acuerdo a
					los presentes Términos y Condiciones. Usted debe usar este
					sitio solamente para realizar o comprar reservaciones
					legítimas y no lo podrá usar para ningún otro propósito,
					incluida pero sin limitación a hacer alguna reservación
					especulativa, falsa o fraudulenta.
				</p>
				<p>
					10. Este Sitio y su contenido referido en él, de manera
					enunciativa más no limitativa: los textos, párrafos,
					enunciados, combinación específica de palabras, letras o
					elementos fonéticos, gráficos, iconos de botones, marcas,
					logotipos, obras de diseño gráfico, fotografías, avisos
					comerciales, patentes, método comercial, combinación
					específica de colores y formas, material editorial, formatos
					impresos, formatos de búsqueda, descargas digitales,
					compilaciones de datos, códigos fuente, software, son
					propiedad exclusiva del proveedor o bien cuenta con todas
					las Licencias y Autorizaciones para tal uso, por tanto Usted
					no puede copiar, reproducir, volver a publicar, cargar,
					modificar, transmitir o distribuir dicho contenido sin tener
					la autorización previa y por escrito del proveedor, excepto,
					en los siguientes casos:
				</p>
				<p>
					a. En que Usted descargue para ver e imprimir el material
					que se encuentra contenido en este Sitio ó
				</p>
				<p>
					b. Descargue o imprima el material que le sea enviado a su
					correo electrónico por parte del proveedor, en ambas
					situaciones Usted única y exclusivamente podrá utilizarlo
					para su uso personal, quedando estrictamente prohibida
					cualquier reproducción total o parcial del Sitio o cualquier
					uso de su contenido destinado al lucro comercial.
				</p>
				<p>
					c. Usted no puede usar ningún dispositivo "robótico," de
					"spider" u otro dispositivo automático, programa, algoritmo
					o metodología que tenga procesos o funciones similares, o
					ningún proceso manual, para monitorear o copiar cualquiera
					de las páginas Web, datos o contenido, código fuente, que se
					encuentre en este Sitio en ningún caso y sin el
					consentimiento previo y por escrito del proveedor.
				</p>
				<p>
					11. De acuerdo a lo anterior, Usted manifiesta estar de
					acuerdo en que no copiará, publicará, transmitirá,
					modificará o transferirá de ninguna otra manera los datos o
					contenido de este Sitio a ningún computador, servidor, sitio
					Web, o a otro medio de distribución masiva, para uso de
					ninguna empresa comercial, cualquier sitio, página web, o
					empresa nacional o extranjera que represente una actividad
					económica.
				</p>
				<p>
					12. Usted también manifiesta estar de acuerdo en que no
					usará ningún dispositivo de ninguna índole, software o
					rutina para interferir con el desempeño propio de este
					sitio. El uso no autorizado que Usted haga de este sitio ó
					cualquier reproducción total o parcial del Sitio y/o
					contenido y de su material, puede violar las leyes que rigen
					la propiedad de Derechos de Autor, Derechos de Propiedad
					Industrial y otras leyes.
				</p>
				<p>
					13. Usted deberá respetar todos los avisos sobre Derechos de
					Autor y Derechos de Propiedad Industrial y no podrá alterar,
					ocultar, o destruir ninguno de estos avisos. El uso de tal
					material en cualquier otro sitio web o en cualquier ambiente
					de computadores en red, está prohibido.
				</p>
				<p>
					14. Está prohibido por el proveedor que usted coloque o
					transmita cualquier material que vaya en contra de la ley,
					amenazante, injurioso, difamatorio, obsceno, indecente,
					pornográfico, profano, o cualquier material que pueda
					constituir o animar a otros a tener conductas que se puedan
					considerar delitos criminales, que deriven en delitos
					civiles, o que de cualquier otra forma violen cualquier ley.
					Además, Usted tiene prohibido exhibir o transmitir cualquier
					información que:
				</p>
				<p>
					a. Infrinja los derechos que tengan otras personas, o que
					violen su privacidad, o derechos de publicidad,
				</p>
				<p>
					b. Esté protegida por Derechos de Autor, de Marca Registrada
					u otros Derechos de Propiedad, a menos que se tenga la
					autorización expresa y por escrito del proveedor o del
					propietario de tales derechos,
				</p>
				<p>
					c. Que contenga un virus, bug o cualquier otro ítem
					peligroso, o
				</p>
				<p>
					d. Que sea usada para confabularse ilegalmente en contra de
					otra persona en detrimento de su derecho a comercializar o a
					competir.
				</p>
				<p>
					15. Usted será el único responsable por cualquier daño que
					se cause por cualquier violación a los Derechos de Autor, de
					Propiedad Industrial, o a cualquier otra clase de Propiedad
					de Derechos, o por cualquier otro daño que se ocasione por
					el mal uso que usted haga de este sitio.
				</p>
				<p>POLÍTICAS DE RESERVACIÓN, PAGO Y CANCELACIÓN</p>
				<p>
					16. Le sugerimos leer cuidadosamente nuestras políticas de
					cancelación.
				</p>
				<p>
					17. Cualquier cambio de reservación está sujeto a
					disponibilidad y re-cotización de tarifas. Para mayor
					información, favor de contactarnos por chat en línea, correo
					electrónico o por teléfono, mencionando su número de
					reservación.
				</p>
				<p>
					18. Una reservación se considera completada, cuando Usted
					cuenta con un número de confirmación.
				</p>
				<p>
					19. Todas las reservaciones son solicitadas en base a
					disponibilidad. Todos los pasajeros deben viajar en el mismo
					itinerario para garantizar un viaje en grupo.
				</p>
				<p>
					20. Los precios incluyen todos aquellos servicios que
					estuvieren expresamente detallados en la documentación de
					viaje, entre ello:
				</p>
				<p>
					a. Transporte, según lo especificado en el boleto de viaje o
					Itinerario de Boleto Electrónico o voucher;
				</p>
				<p>
					b. Renta de autos y los seguros indicados al momento de
					reservar, según se detalle en los vouchers respectivos.
				</p>
				<p>
					21. Servicios o rubros no incluidos en los precios: En los
					precios base de los servicios contratados no están
					incluidos:
				</p>
				<p>
					a. Todo aquello que no se encuentre debidamente detallado en
					el voucher, que implique un cargo adicional a lo abonado al
					momento de recibir los voucher.
				</p>
				<p>
					b. Extras, bebidas, gastos de índole personal (entre ellos,
					lavado y planchado de ropa, comunicaciones, propinas, etc.),
					visas, tasa de embarque o de aeropuerto, tasa sobre
					servicios, IVA y otros impuestos actuales y/o futuros y/o
					todo servicio o gasto que no se encuentre expresamente
					indicado en el voucher.
				</p>
				<p>
					c. Los servicios adicionales, como ser porta esquíes, silla
					de bebes, amenidades, tiempo extra de meet & greet, seguros
					optativos, etc.
				</p>
				<p>
					d. Los gastos por prolongación de los servicios por deseo
					voluntario de los Pasajeros como así tampoco las estadías,
					comidas y/o gastos adicionales y/o perjuicios producidos por
					cancelaciones, demoras en las salidas o llegadas de los
					medios de transporte o por razones imprevistas ajenas a el
					proveedor y/o derivados de causas de fuerza mayor o fuera de
					control del proveedor.
				</p>
				<p>e. Los gastos e intereses de operaciones a crédito.</p>
				<p>
					f. Cargos para operaciones internacionales, lo que puede
					variar ocasionalmente, además de cualquier impuesto
					requerido de acuerdo con la ley aplicable. Si está
					realizando una reservación con un PROVEEDOR fuera de México
					utilizando una tarjeta de crédito o débito de México, su
					Banco puede convertir el monto a pagar a su moneda local y
					cobrarle una tasa de conversión.
				</p>
				<p>
					g. Variaciones al tipo de cambio utilizado por el banco
					diferente a la tasa que se muestra en nuestro convertidor de
					divisas; el monto ingresado en su Estado de Cuenta de
					tarjeta de crédito puede estar en pesos mexicanos y ser un
					monto diferente al que se muestra en la página de resumen de
					facturación para una reservación realizada en el "SITIO". El
					tipo de cambio y el cargo por la transacción internacional
					son determinados solamente por su banco el día en que se
					procesa la transacción.
				</p>
				<p>
					22. En caso de desistimiento por parte del pasajero de
					servicios contratados en firme por el proveedor a terceros,
					las cancelaciones estarán sujetas a las condiciones
					contractuales bajo las cuales estos últimos presten sus
					servicios. En todos los casos de reintegro, se podrá retener
					el precio por los gastos incurridos más la comisión del diez
					por ciento de los servicios contratados con terceros.
				</p>
				<p>
					23. La postergación o adelanto de las fechas estipuladas en
					cada caso, podrá realizarse de acuerdo con las modalidades,
					condiciones y disponibilidad de los distintos prestadores de
					servicios, debiendo abonarse la correspondiente penalidad
					aplicada por el prestador y una comisión del 10% en concepto
					de modificaciones y, en su caso, las diferencias tarifarias
					por cambio de temporada, si correspondieren. El proveedor
					queda eximido de toda responsabilidad en caso de no poder
					satisfacer los cambios solicitados por el Pasajero,
					aplicándose en tal caso lo previsto en las políticas de
					Cancelación.
				</p>
				<p>
					24. Para Cancelación de Reservaciones de Transportación
					Terrestre
				</p>
				<p>
					a. Cancelaciones hechas 3 días o más antes de la fecha de
					llegada sin cargo.
				</p>
				<p>
					b. Cancelaciones hechas de 0 a 2 días antes de la fecha de
					llegada aplica un cargo de 25%.
				</p>
				<p>
					c. No aplican reembolsos si no se presenta al aeropuerto o
					punto de encuentro el día y hora que esté programado el
					servicio de transportación
				</p>
				<p>
					25. Las fechas límite de cancelación se aplicarán de acuerdo
					con el horario vigente en el país de destino.
				</p>
				<p>
					26. Recomendamos que toda solicitud de cancelación sea
					informada por escrito vía e-mail a el proveedor, mencionando
					su número de reservación.
				</p>
				<p>
					27. Los reclamos y/o reembolsos deberán ser presentados
					dentro de los 7 días de finalizado el viaje, por escrito,
					acompañados de comprobantes y documentación respaldatoria y
					firmados por el pasajero. Pasado este término no será
					atendido reclamo alguno. En ningún caso, el proveedor
					costeará los eventuales costos y/o cargos y/o impuestos y/o
					gravámenes derivados de las transferencias bancarias o
					similares que se utilicen para realizar cualquier tipo de
					devolución y/o reembolso y/o reintegro.
				</p>
				<p>PRIVACIDAD Y SEGURIDAD</p>
				<p>
					28. Usted manifiesta que ha leído la POLITICA DE PRIVACIDAD
					Y SEGURIDAD de el proveedor, los términos incorporados en
					ella, y está de acuerdo en que los términos de tal política
					son razonables.
				</p>
				<p>
					29. Usted consiente en que su información personal sea usada
					por el proveedor y/o por sus proveedores y distribuidores
					terceras partes de acuerdo con los términos de la Política
					de Privacidad del proveedor y para los propósitos
					establecidos en ella.
				</p>
				<p>LIMITACIÓN DE LA RESPONSABILIDAD</p>
				<p>
					30. El proveedor no será responsable, ni asume ninguna
					responsabilidad por cualquier daño o virus que pueda
					infectar su computador o cualquier otra propiedad de usted
					debido al acceso, uso o navegación que haga de este sitio, o
					por la descarga de cualquier material, dato, texto, imagen,
					video o audio desde el sitio.
				</p>
				<p>
					31. En ningún caso el proveedor será responsable por
					cualquier lesión, daño, perjuicio, perdida, reclamo o
					cualquier daño especial, punitivo, indirecto, incidental por
					negligencia, o ilícito, que resulte de
				</p>
				<p>
					a. Cualquier uso de este sitio o del contenido que aquí se
					encuentre;
				</p>
				<p>
					b. Cualquier falla o demora (incluyendo, sin que haya
					limite, el uso de, o la imposibilidad de usar cualquier
					componente de este sitio para los servicios de reservación o
					emisión de boletos), o
				</p>
				<p>
					c. El funcionamiento o no funcionamiento del proveedor,
					incluido, pero sin estar limitado a, el no funcionamiento
					resultado de la bancarrota, reorganización, declaración de
					insolvencia, disolución o liquidación.
				</p>
				<p>
					32. Usted deberá responder de los daños que genere a el
					proveedor, que resulten de cualquier mal uso o uso
					incorrecto que pudiera hacer del Sitio, infraestructura y
					bienes, o sobre cualquier operación fraudulenta o con fines
					contrarios a los servicios prestados en el sitio o aquellos
					que vayan en contra de las leyes y reglamentos vigentes y
					aplicables.
				</p>
				<p>
					33. Este Sitio contiene enlaces a otros sitios Web los
					cuales se proporcionan únicamente para su conveniencia y no
					como respaldo a el proveedor y ellos pertenecen a los
					proveedores o distribuidores terceras partes de los
					contenidos de tales otros sitios
				</p>
				<p>
					34. Web. El proveedor no será responsable por el contenido
					de ningún otro sitio Web y no representa o garantiza ningún
					otro sitio Web o el contenido o material de tales sitios. Si
					Usted decide acceder a otros sitios Web, lo hace bajo su
					propia responsabilidad.
				</p>
				<p>INFORMACIÓN VERDADERA</p>
				<p>
					35. Usted reconoce y acepta su total obligación y
					responsabilidad de proporcionar datos veraces y correctos
					sobre las edades, sexo, nombres o apellidos tanto de Usted
					como de los demás usuarios-turistas que lo acompañen o que
					lo comisionen para hacer uso de éste Sitio, así como de los
					datos e información completa para la formación del
					itinerario y la reservación de servicios terrestres y
					marítimos (hoteles, visitas, autos de alquiler,
					restaurantes, cruceros, etc) liberando al proveedor de
					cualquier responsabilidad por cambios de itinerario
					generados por datos mal proporcionados, o por cualquier
					error en los datos proporcionados por Usted para la emisión
					o compra de boletos aéreos como puede ser el caso de edades,
					sexo, nombres, apellidos, fechas, formas de pago, rutas,
					líneas aéreas, horarios, clases, categorías, condiciones
					especiales, etc., apegándose en su caso a las cláusulas de
					cancelación para servicios aéreos, terrestres, marítimos o
					de cruceros expresadas en estos Términos y Condiciones.
				</p>
				<p>REGLAMENTOS DE LOS SERVICIOS</p>
				<p>
					36. Usted se compromete a apegarse y a respetar los
					reglamentos y condiciones de servicio establecidas por cada
					uno de los Proveedores y prestadores directos de servicios
					contratados por Usted a través de la intermediación del
					proveedor, por lo que el proveedor debe hacer de su
					conocimiento las que sean más importantes, no obstante el
					proveedor podrá hacer de su conocimiento nuevas o
					adicionales condiciones de servicio sobre las cuales no se
					tiene responsabilidad alguna y en consecuencia declina
					cualquier responsabilidad que pudiera derivar por su
					incumplimiento.
				</p>
				<p>
					37. Usted deberá, por medios propios, proveerse de los
					pasaportes o documentos de migración requeridos por las
					autoridades de los Estados Unidos Mexicanos, y de los países
					de destino ó de transito, tales como visas, permisos
					sanitarios, y todos aquellos documentos requeridos por las
					autoridades Aduaneras, Aéreas, Marítimas, Aeroportuarias,
					Federales, Estatales, Municipales etc., que resulten
					necesarias para realizar su viaje, liberando a el proveedor
					sobre cualquier problema que llegare a surgir con dichas
					autoridades.
				</p>
				<p>
					38. En los casos de viajes internacionales Usted se
					compromete a presentarse en los aeropuertos y documentarse
					ante las aerolíneas mínimo con TRES HORAS DE ANTICIPACION en
					vuelos internacionales y DOS HORAS DE ANTICIPACION en vuelos
					nacionales, salvo la instrucción expresa y por escrito que
					reciba de el proveedor y/o alguna otra instrucción para
					presentarse aún antes por parte dichas Autoridades.
				</p>
				<p>CONVENIOS ADICIONALES CON PROVEEDORES</p>
				<p>
					39. El proveedor queda relevado de cualquier responsabilidad
					derivada de convenios adicionales y fuera de la
					intermediación con el proveedor que se hayan celebrado entre
					Usted y demás Proveedores prestadores directos de los
					servicios, como son transportistas aéreos y terrestres,
					navieras, cruceros, hoteles, arrendadoras de autos, etc.
				</p>
				<p>
					40. A pesar de la estrecha comunicación establecida por el
					proveedor con los prestadores de servicios, existe la
					posibilidad de que la vigencia de algunos precios haya
					expirado o provenga de información erróneamente
					proporcionada por dichos proveedores con la que nuestra base
					de datos es alimentada. No obstante, esto, el proveedor toma
					la precaución de verificar las tarifas que aplican con cada
					reservación.
				</p>
				<p>
					41. En caso de que el precio correcto sea menor a la
					cantidad cotizada, el proveedor aplicará el monto menor. En
					caso de que el precio correcto sea mayor a la cantidad
					cotizada, el proveedor se pondrá oportunamente en contacto
					con Usted, informándole la cantidad correcta o bien,
					registrando la cancelación de la reservación a petición suya
					por no estar de acuerdo con el incremento en precio,
					deslindando expresamente a el proveedor y sus prestadores de
					servicios de cualquier responsabilidad o pago de
					indemnización por inconvenientes causados por dicha
					cancelación.
				</p>
				<p>DESLINDE DE RESPONSABILIDADES</p>
				<p>
					42. El proveedor funge como agencia por internet, presta
					servicios en calidad de agente de reservaciones
					intermediario entre Usted y el Proveedor o Proveedores
					directos de los servicios turísticos promocionados en este
					Sitio. El proveedor establece negociaciones comerciales
					dentro de sus propios estándares de calidad y servicio,
					tales como, pero no limitados a: hospedaje en hoteles,
					transportación terrestre, marítima o aérea y otras
					actividades turísticas afines como tours y excursiones,
					eligiendo sólo a los proveedores más calificados y de mayor
					prestigio del mercado.
				</p>
				<p>
					43. Toda vez que el carácter jurídico del proveedor es el de
					fungir única y exclusivamente como INTERMEDIARIO y no como
					proveedor directo de los servicios turísticos, el proveedor
					no asume ni asumirá ninguna responsabilidad generada de
					relación alguna ante Usted y los Prestadores Finales de
					Servicios y a su vez Usted libera a el proveedor sobre
					cualquier responsabilidad ante cualquier falla o falta de
					cumplimiento por parte del Proveedor o prestadores directos
					de los servicios turísticos, incluyendo sin limitación
					alguna cualquier falla o cumplimiento por parte de las
					aerolíneas, hoteles, proveedores de hospedaje temporal,
					navieras y toda clase de embarcaciones, proveedores de
					deportes acuáticos, agencias de renta de autos, agencias de
					transportación, operadores de tours, instructores de buceo,
					instructores de esnórquel, instructores de golf,
					instructores de nado con delfines, instructores de pesca,
					proveedores de deportes de aventura y deportes extremos,
					parques acuáticos, parques ecológicos, y en general sobre
					cualquier falla o falta de cumplimiento por parte de todos
					aquellos servicios turísticos que sean proporcionados de
					manera directa por uno o varios Proveedores y no de manera
					directa por el proveedor.
				</p>
				<p>
					44. Sin embargo, el proveedor podrá a su libre discreción
					proporcionarle datos de contacto y/o domicilio del prestador
					de servicios a efecto de que Usted ejerza la reclamación
					respectiva o vía de reclamación que Usted considere
					necesaria.
				</p>
				<p>
					45. El proveedor no ofrece, ni ofrecerá ninguna garantía
					sobre los servicios proporcionados de manera directa por los
					prestadores de servicios, ni garantiza la posición
					financiera de los mismos o cualquier reembolso a Usted
					ocasionado por cualquier pérdida experimentada como
					resultado de la condición financiera de dicho Proveedor,
					incluyendo sin limitación alguna, la insolvencia económica,
					o Concurso Mercantil en que se llegare a declarar dicho
					Proveedor.
				</p>
				<p>
					46. En el evento en que el prestador de servicios falle en
					el cumplimiento de alguno de sus servicios, por los cuales
					el pago Usted ya ha realizado, el solo recurso del reembolso
					deberá tramitarse directamente ante el Proveedor que falló
					en la prestación de sus servicios, o pueda solicitar la
					cobertura del seguro en el caso de ser aplicable o ante
					cualquier otra tercera parte, a menos que el anterior fallo
					sea ocasionado de manera directa por el proveedor.
				</p>
				<p>
					47. El proveedor, no asume responsabilidad ante Usted y
					Usted libera de toda responsabilidad al proveedor por actos,
					omisiones o cualquier tipo de queja o insatisfacción
					derivados de los servicios proporcionados por los
					proveedores directos de servicios en el Sitio anunciados, en
					virtud de que el proveedor no tiene control alguno o
					relación legal sobre su personal, equipo, operación o
					bienes.
				</p>
				<p>
					48. En aquellas situaciones en que el proveedor directo
					falle en la prestación de sus servicios Usted podrá intentar
					cualquier recurso permitido por las leyes aplicables en
					contra del Proveedor. El proveedor le garantiza que cuenta
					con altos estándares de servicio y tomará un cuidado
					especial en la selección de los prestadores de servicios
					para evitar en todo momento la falla de los mismos.
				</p>
				<p>
					49. El proveedor no será responsable y Usted libera de toda
					responsabilidad a el proveedor respecto de:
				</p>
				<p>
					a. La veracidad de las fotografías mostradas en su Sitio ya
					que las mismas son solamente representativas y no garantizan
					que a su llegada, todo sea exactamente igual como en ellas
					se aprecia y en el Sitio describe;
				</p>
				<p>
					b. Las descripciones de los servicios de viaje son
					actualizadas por el proveedor a su mejor conocimiento, pero
					no garantizan que todo será exactamente igual a su llegada;
				</p>
				<p>
					c. Cualquier tipo de falla por parte de Usted en obtener la
					documentación requerida para su viaje tal como, pero no
					limitada a, pasaportes, visas, certificados etc.
				</p>
				<p>
					d. Cualquier tipo de falla por parte de Usted para seguir
					las instrucciones de viaje incluyendo, pero no limitado a,
					horarios de salida de aeropuerto, hora y fecha de entrada y
					salida en hoteles, políticas de canje de cupones etc;
				</p>
				<p>
					e. Por falta de condiciones financieras por parte del
					Proveedor para proporcionar el servicio.
				</p>
				<p>
					f. Respecto a los términos y condiciones y/o políticas de
					los Proveedores directos de servicios. El proveedor no
					asumirá responsabilidad alguna y Usted libera de toda
					responsabilidad al proveedor, así como de toda reclamación,
					costo, gasto o pérdida que Usted pudiera sufrir incluyendo
					cualquier herida personal o de terceras personas, accidentes
					o deceso, daños a pertenencias personales, pérdida de
					diversión, enojo, desilusión, angustia o frustración, ya sea
					mental o física, siempre que sean resultado de:
				</p>
				<p>
					Cualquier falla o falta por parte del Proveedor directo al
					proporcionar el servicio.
				</p>
				<p>
					i. Cualquier falla o falta por parte de Usted al momento de
					disfrutar de los servicios contratados.
				</p>
				<p>
					ii. Cualquier falla o falta de usted al observar o cumplir
					los términos y condiciones, políticas, instrucciones,
					recomendaciones, medias de seguridad, etc de los Proveedores
					finales del servicio.
				</p>
				<p>
					iii. Casos de fuerza mayor o casos fortuitos como lo son:
					huelgas, atrasos, terremotos, conflictos bélicos, huracanes,
					nevadas, etc. (que no le sean imputables al proveedor),
				</p>
				<p>
					iv. Actos u omisiones de cualquiera otra persona que no sea
					el proveedor.
				</p>
				<p>
					v. Enfermedad, robo, disputas laborales, fallas mecánicas,
					cuarentena, acciones gubernamentales, clima, o cualquier
					otra causa ajena al control directo del proveedor.
				</p>
				<p>
					vi. Ante la insistencia de Usted para realizar cualquier
					tipo de viaje, actividad turística, tomar un tour,
					excursión, tomar cualquier tipo de transportación aérea,
					terrestre, marítima y de cualquier tipo, bajo condiciones
					precarias de salud o que no sean optimas para el desarrollo
					de tales actividades, así como el desarrollo por Usted de
					cualquier tipo de deportes extremos o actividades que puedan
					implicar algún peligro y que fueron realizados bajo su
					entero conocimiento, o cuando padezca algún tipo de
					enfermedad, condición médica, incapacidad, algún tipo de
					alergia, se encuentre bajo medicación o prescripción médica,
					indicaciones de reposo y/o todas aquellas condiciones
					médicas que bajo su propio riesgo fueron omitidas y que
					pudieran resultar en un deterioro a su salud al momento de
					realizar dichas actividades bajo consciencia de causa, bajo
					dichas ocasiones siempre se considerarán fueron realizadas
					bajo su propio riesgo, liberando Usted al proveedor respecto
					de cualquier denuncia, demanda, queja, inconformidad,
					recompensa, indemnización, compensación, cortesía, etc.,
					derivados de cualquier incidente ocasionado durante el
					desarrollo de cualquier servicio o actividad y cualquier
					evento futuro derivado del incidente inicial por cualquiera
					de estas causas.
				</p>
				<p>
					vii. Cualquier otro evento que no se encuentre bajo el
					control directo del proveedor. Usted será responsable de
					verificar que el Proveedor final cuente con servicios
					especiales como puede ser acceso, comodidades y servicios
					para personas con capacidades diferentes, físicas o de
					cualquier otro tipo, lo cual se le sugiere lo haga
					previamente a la realización de su reservación. Usted será
					responsable de leer y de sujetarse a los términos y
					condiciones y/o políticas de los Proveedores finales de
					servicios.
				</p>
				<p>
					50. El reembolso total no será procedente en situaciones en
					las que el viaje tenga que ser cancelado, interrumpido y/o
					pospuesto por el proveedor por razones que estén fuera de su
					control (causas de fuerza mayor, tales como, pero no
					limitadas a, clima, huracán, terremotos, actos de
					terrorismo, etc.) y en los que las obligaciones
					contractuales del proveedor con sus prestadores de servicios
					no le permitan obtener reembolso de la suma pagada o a ser
					pagada al Proveedor por cuenta del cliente.
				</p>
				<p>
					51. En ningún caso el proveedor responderá frente a los
					Pasajeros por daños indirectos o lucro cesante alegado por
					ellos mientras no se trate de hechos en los cuales los
					funcionarios, empleados o autoridades del proveedor no hayan
					tenido participación en ellos.
				</p>
				<p>TERMINACIÓN DEL CONTRATO</p>
				<p>
					52. El proveedor podrá dar por terminado estos Términos y
					Condiciones y/o cualquiera de sus servicios en cualquier
					momento sin necesidad de avisar o notificar dicha
					terminación, por cualquier causa o motivo, incluyendo por
					cualquier uso inapropiado de este sitio o su incumplimiento
					con estos Términos y Condiciones.
				</p>
				<p>
					53. No obstante, tal terminación no afectará ningún derecho
					u obligación contraída por el proveedor ante Usted una vez
					confirmada una reservación.
				</p>
				<p>
					54. El proveedor se reserva el derecho de hacer que abandone
					un Plan de Viaje y/o Tour en cualquier punto del mismo todo
					pasajero cuya conducta, modo de obrar, estado de salud, u
					otra razón grave que a juicio del proveedor provoque peligro
					o cause molestias a los restantes viajeros o pueda malograr
					el éxito de la excursión o el normal desarrollo de la misma,
					o que pueda causar daños a terceros.
				</p>
				<p>CESIÓN DE LOS SERVICIOS</p>
				<p>
					55. Usted no podrá ceder, subcontratar, o delegar sus
					derechos, obligaciones o deberes aquí contraídos.
				</p>
				<p>LEGISLACIÓN APLICABLE</p>
				<p>
					56. Estos Términos y Condiciones se regirán por las leyes
					del estado Quintana Roo.
				</p>
				<p>
					57. Usted manifiesta que conoce los alcances del presente
					Contrato, cuyos Términos y Condiciones son la expresión fiel
					de su voluntad y se someten para el caso de incumplimiento o
					interpretación a la competencia de la Procuraduría Federal
					del Consumidor, en la vía administrativa, como instancia
					conciliatoria, para resolver las diferencias que pudieran
					suscitarse y en caso de subsistir las mismas Usted está de
					acuerdo en someterse a la competencia de las Leyes y
					Tribunales de Cancún, Quintana Roo, bajo su jurisdicción
					exclusiva, renunciando a su vez a cualquier otra
					jurisdicción que en razón de sus domicilios presentes o
					futuros o por la ubicación de sus bienes o por su
					nacionalidad pudiera corresponderle.
				</p>
				<p>
					58. En la medida que lo permita la ley de aplicación,
					ninguna queja, reclamo o causa de acción que esté
					relacionado con su acceso o uso de este Sitio deberá
					presentarse posterior al término transcurrido de un (1) año
					siguiente a la fecha en que tal queja, reclamo o acción se
					haya presentado o la compra haya sido completada.
				</p>
			</v-container>
			<v-container v-else style="max-height: 60vh; overflow:auto;">
				<h2>Terms and conditions</h2>
				<hr />

				<h4>
					PLEASE READ THE TERMS AND CONDITIONS CAREFULLY BEFORE USING
					THIS SITE.
				</h4>

				INTRODUCTION

				<p>
					1. Thank you for using the services of Shuttle Central,
					collectively referred to as the “Provider”. Your use of this
					Site is expressly conditioned on your acceptance of these
					Terms and Conditions. By using this site, you agree to the
					Terms and Conditions. Shuttle Central reserves the right to
					add or modify the Terms and Conditions at any time.
				</p>

				<p>DECLARATIONS</p>

				<p>
					2. You declare under protest of telling the truth, appearing
					by your own and personal right and / or through your legal
					representative, that you are a natural or legal person, of
					legal age, capable, and with sufficient means or with
					financial capacity to be bound under these Terms and
					Conditions, as well as with interest in acquiring the
					intermediation and reservation services specified here, and
					that for this it resorts to Shuttle Central in order to act
					as an intermediary between you and the Suppliers direct
					providers of tourist services. offered on this site.
				</p>

				<p>
					3. You declare under protest of being the truth, that you
					acknowledge that the services provided by Shuttle Central
					consist ONLY AND EXCLUSIVELY OF THE INTERMEDIATION BETWEEN
					YOU AND THE DIRECT PROVIDER OF THE SERVICES. Shuttle Central
					declares (a) to be a duly incorporated commercial company in
					accordance with Mexican law. (b) That it has authorization
					to provide transportation service by land, sea and / or air,
					it is also registered with the Tax authorities with the
					Federal Taxpayers Registry number SCE190516DZ1.
				</p>

				<p>4. That its corporate purpose consists of the power to:</p>

				<p>
					a. Act as an intermediary for the reservation of spaces in
					the means of transport and issue the corresponding tickets
					on behalf of the carriers and in favor of the tourists;
				</p>
				<p>
					b. Serve as an intermediary between tourists and
					transportation service providers of any kind;
				</p>
				<p>
					c. Reserve rooms and other related services for tourists;
					provide tourists with reservation services for tourist
					attraction sites;
				</p>
				<p>
					d. Serve as an intermediary between the other providers of
					tourist services and other travel agencies;
				</p>
				<p>
					e. Provide tourist information service and disseminate
					propaganda material of this nature that is freely
					distributed.
				</p>

				<p>
					5. That for the purposes of these Terms and Conditions, it
					only acts as INTERMEDIARY between you and the Suppliers of
					said tourist services, and that for this purpose, it has the
					technical and administrative capacity, as well as the
					material and human elements necessary to reserve on your
					behalf and at your request the services referred to in these
					Terms and Conditions.
				</p>

				<p>
					6. Once the reservations are confirmed by the provider
					containing the tourist services chosen by you (according to
					their availability), it will be concluded with the provision
					of the intermediation service by the provider, since said
					tourist services will be provided to You by Providers
					directly.
				</p>

				<p>USE OF THE SITE</p>

				<p>
					7. Regardless of your Country of origin, you declare that
					you are of sufficient legal age to use this site, subscribe
					and be bound by these Terms and Conditions, as well as to be
					responsible for the legal obligations that bind you with any
					liability incurred for the use of this Site.
				</p>

				<p>
					8. You understand that you will be financially responsible
					for all the use that you make of this Site and those who use
					it using your information to access it.
				</p>

				<p>
					9. The Provider grants you a limited, non-transferable,
					revocable license to use the site in accordance with these
					Terms and Conditions. You must use this site only to make or
					purchase legitimate reservations and may not use it for any
					other purpose, including but not limited to making any
					speculative, false or fraudulent reservation.
				</p>

				<p>
					10. This Site and its content referred to in it, but not
					limited to: texts, paragraphs, statements, specific
					combination of words, letters or phonetic elements,
					graphics, button icons, brands, logos, graphic design works,
					photographs, commercial notices, patents, commercial method,
					specific combination of colors and shapes, editorial
					material, printed formats, search formats, digital
					downloads, data compilations, source codes, software, are
					the exclusive property of the provider or have all the
					Licenses and Authorizations for such use, therefore, you
					cannot copy, reproduce, republish, upload, modify, transmit
					or distribute said content without having the prior written
					authorization of the provider, except in the following
					cases:
				</p>

				<p>
					a. In which you download to view and print the material that
					is contained in this Site or
				</p>
				<p>
					b. b. Download or print the material that is sent to your
					email by the provider, in both situations you may only and
					exclusively use it for your personal use, being strictly
					prohibited any total or partial reproduction of the Site or
					any use of its content intended for commercial profit.
				</p>
				<p>
					c. c. You may not use any "robotic", "spider" or other
					automatic device, program, algorithm or methodology that has
					similar processes or functions, or any manual process, to
					monitor or copy any of the Web pages, data or content,
					source code, found on this Site in no case and without the
					prior written consent of the provider.
				</p>

				<p>
					11. In accordance with the foregoing, you agree that you
					will not copy, publish, transmit, modify or otherwise
					transfer the data or content of this Site to any computer,
					server, website, or other means of distribution. massive,
					for the use of any commercial company, any site, web page,
					or national or foreign company that represents an economic
					activity.
				</p>

				<p>
					12. You also agree that you will not use any device of any
					kind, software or routine to interfere with the performance
					of this site. The unauthorized use that you make of this
					site or any total or partial reproduction of the Site and /
					or its content and its material, may violate the laws that
					govern the property of Copyright, Industrial Property Rights
					and other laws.
				</p>

				<p>
					13. You must respect all the notices on Copyright and
					Industrial Property Rights and may not alter, hide, or
					destroy any of these notices. The use of such material on
					any other website or in any networked computer environment
					is prohibited.
				</p>

				<p>
					14. It is prohibited by the provider that you post or
					transmit any material that is against the law, threatening,
					libelous, defamatory, obscene, indecent, pornographic,
					profane, or any material that may constitute or encourage
					others to engage in conduct that may be considered criminal
					offenses, resulting in civil offenses, or that in any other
					way violate any law. In addition, you are prohibited from
					displaying or transmitting any information that:
				</p>

				<p>
					a. Infringe the rights that other people have, or that
					violate their privacy, or publicity rights,
				</p>
				<p>
					b. It is protected by Copyright, Trademark or other Property
					Rights, unless you have the express written authorization of
					the provider or the owner of such rights,
				</p>
				<p>c. Contains a virus, bug or any other dangerous item, or</p>
				<p>
					d. That it is used to collude illegally against another
					person to the detriment of their right to market or compete.
				</p>

				<p>
					15. You will be solely responsible for any damage that is
					caused by any violation of Copyright, Industrial Property,
					or any other kind of Property Rights, or for any other
					damage caused by the misuse you make of this site.
				</p>
				<p>RESERVATION, PAYMENT AND CANCELLATION POLICIES</p>

				<p>
					16. We suggest you read our cancellation policies carefully.
				</p>

				<p>
					17. Any change of reservation is subject to availability and
					re-quotation of rates. For more information, please contact
					us by online chat, email or by phone, mentioning your
					reservation number.
				</p>

				<p>
					18. A reservation is considered completed when you have a
					confirmation number.
				</p>

				<p>
					19. All reservations are requested based on availability.
					All passengers must travel on the same itinerary to
					guarantee a group trip.
				</p>

				<p>
					20. The prices include all those services that are expressly
					detailed in the travel documentation, including:
				</p>

				<p>
					a. Transportation, as specified in the travel ticket or
					Electronic Ticket Itinerary or voucher;
				</p>
				<p>
					b. Car rental and insurance indicated at the time of
					booking, as detailed in the respective vouchers.
				</p>

				<p>
					21. Services or items not included in the prices: The base
					prices of the contracted services do not include:
				</p>

				<p>
					a. Anything that is not meticulously detailed in the
					voucher, which implies an additional charge to what was paid
					at the time of receiving the voucher.
				</p>

				<p>
					b. Extras, drinks, personal expenses (among them, washing
					and ironing clothes, communications, tips, etc.), visas,
					boarding or airport tax, service tax, VAT and other current
					and / or future taxes and / or or any service or expense
					that is not expressly indicated in the voucher.
				</p>

				<p>
					c. Additional services, such as ski racks, baby seats,
					amenities, extra meet & greet time, optional insurance, etc.
				</p>

				<p>
					d. The expenses for prolonging the services by voluntary
					desire of the Passengers as well as the stays, meals and /
					or additional expenses and / or damages produced by
					cancellations, delays in the departures or arrivals of the
					means of transport or for unforeseen reasons beyond the
					control of the supplier and / or derived from causes of
					force majeure or beyond the supplier's control, and
				</p>

				<p>e. The expenses and interests of credit operations.</p>

				<p>
					f. Charges for international operations, which may vary from
					time to time, in addition to any taxes required in
					accordance with applicable law. If you are making a
					reservation with a PROVIDER outside of Mexico using a
					Mexican credit or debit card, your Bank may convert the
					amount to be paid into your local currency and charge you a
					conversion fee.
				</p>

				<p>
					g. Variations at the exchange rate used by the bank other
					than the rate shown in our currency converter; The amount
					entered in your credit card statement may be in Mexican
					pesos and be a different amount than the one shown on the
					billing summary page for a reservation made on the "SITE".
					The exchange rate and charge for the international
					transaction are determined only by your bank on the day the
					transaction is processed.
				</p>

				<p>
					22. In the event of withdrawal by the passenger from
					services firmly contracted by the provider to third parties,
					cancellations will be subject to the contractual conditions
					under which the latter provide their services. In all cases
					of reimbursement, the price may be retained for the expenses
					incurred plus the commission of ten percent of the services
					contracted with third parties.
				</p>

				<p>
					23. The postponement or advancement of the dates stipulated
					in each case, may be carried out in accordance with the
					modalities, conditions and availability of the different
					service providers, having to pay the corresponding penalty
					applied by the provider and a commission of 10% in concept
					of modifications and, where appropriate, rate differences
					due to seasonal change, if applicable. The provider is
					exempt from all responsibility in case of not being able to
					satisfy the changes requested by the Passenger, applying in
					such case the provisions of the Cancellation policies.
				</p>

				<p>24. For Cancellation of Land Transportation Reservations.</p>

				<p>
					a. Cancellations made 3 days or more prior to arrival date
					free of charge.
				</p>

				<p>
					b. Cancellations made 0 to 2 days before the arrival date
					apply a 25% charge.
				</p>

				<p>
					c. Refunds do not apply if you do not show up at the airport
					or meeting point on the day and time that the transportation
					service is scheduled
				</p>
				<p>
					25. The cancellation deadlines will be applied according to
					the current schedule in the destination country.
				</p>

				<p>
					26. We recommend that all cancellation requests be informed
					in writing via e-mail to the provider, mentioning their
					reservation number.
				</p>

				<p>
					27. Claims and / or reimbursements must be submitted within
					7 days after the end of the trip, in writing, accompanied by
					vouchers and supporting documentation and signed by the
					passenger. After this term, no claim will be addressed. In
					no case, the provider will pay the eventual costs and / or
					charges and / or taxes and / or levies derived from bank
					transfers or similar that are used to make any type of
					return and / or refund and / or refund.
				</p>

				<p>PRIVACY & SECURITY</p>

				<p>
					28. You represent that you have read the provider's PRIVACY
					AND SECURITY POLICY, the terms incorporated in it, and you
					agree that the terms of such policy are reasonable.
				</p>

				<p>
					29. You consent to your personal information being used by
					the provider and / or its third-party providers and
					distributors in accordance with the terms of the provider's
					Privacy Policy and for the purposes set forth therein.
				</p>

				<p>LIMITATION OF LIABILITY</p>

				<p>
					30. The provider will not be responsible, nor does it assume
					any responsibility for any damage or virus that may infect
					your computer or any other property of you due to the
					access, use or navigation you make of this site, or for the
					download of any material, data , text, image, video or audio
					from the site.
				</p>

				<p>
					31. In no case will the supplier be responsible for any
					injury, damage, loss, loss, claim or any special, punitive,
					indirect, incidental negligent, or unlawful damage resulting
					from:
				</p>

				<p>a. Any use of this site or the content found here;</p>

				<p>
					b. Any failure or delay (including, without limitation, the
					use of, or the inability to use, any component of this site
					for reservation or ticketing services), or
				</p>

				<p>
					c. The operation or non-operation of the provider,
					including, but not limited to, the non-operation resulting
					from bankruptcy, reorganization, declaration of insolvency,
					dissolution, or liquidation.
				</p>

				<p>
					32. You must be liable for the damages generated to the
					provider, resulting from any misuse or incorrect use that
					may be made of the Site, infrastructure and goods, or for
					any fraudulent operation or for purposes contrary to the
					services provided on the site or those that go against the
					laws and regulations in force and applicable.
				</p>

				<p>
					33. This Site contains links to other Web sites which are
					provided solely for your convenience and not as an
					endorsement of the provider and they belong to the
					third-party providers or distributors of the content of such
					other sites
				</p>

				<p>
					34. Web. The provider will not be responsible for the
					content of any other website and does not represent or
					guarantee any other website or the content or material on
					such sites. If you decide to access other websites, you do
					so at your own risk.
				</p>

				<p>TRUE INFORMATION</p>

				<p>
					35. You acknowledge and accept your full obligation and
					responsibility to provide truthful and correct data on the
					ages, sex, names or surnames of both you and the other
					user-tourists who accompany you or who commission you to use
					this Site, as well as the data and complete information for
					the formation of the itinerary and the reservation of land
					and maritime services (hotels, visits, rental cars,
					restaurants, cruises, etc.), releasing the provider from any
					responsibility for itinerary changes generated by bad data
					provided, or for any error in the data provided by you for
					the issuance or purchase of air tickets such as ages, sex,
					names, surnames, dates, forms of payment, routes, airlines,
					schedules, classes, categories , special conditions, etc.,
					adhering to the cancellation clauses for air, land, sea or
					cruise services expressed in these Terms and C conditions.
				</p>

				<p>RULES OF THE SERVICES</p>

				<p>
					36. You agree to adhere to and respect the regulations and
					conditions of service established by each of the Providers
					and direct service providers contracted by you through the
					intermediation of the provider, so the provider must inform
					you of those that are more important, however the provider
					may inform you of new or additional conditions of service
					for which it has no responsibility and consequently declines
					any liability that may arise from non-compliance.
				</p>
				<p>
					37. You must, by your own means, provide yourself with the
					passports or migration documents required by the authorities
					of the United Mexican States, and of the destination or
					transit countries, such as visas, health permits, and all
					those documents required by Customs, Air, Maritime, Airport,
					Federal, State, Municipal authorities, etc., that are
					necessary to make your trip, releasing the supplier from any
					problem that may arise with said authorities.
				</p>

				<p>
					38. In the cases of international travel, you agree to
					appear at the airports and check in with the airlines at
					least THREE HOURS IN ADVANCE on international flights and
					TWO HOURS IN ADVANCE on domestic flights, except for the
					express written instruction you receive from him. provider
					and / or any other instruction to be submitted even earlier
					by said Authorities.
				</p>

				<p>ADDITIONAL AGREEMENTS WITH SUPPLIERS</p>

				<p>
					39. The provider is relieved of any responsibility derived
					from additional agreements and outside the intermediation
					with the provider that have been entered into between you
					and other direct service providers, such as air and land
					carriers, shipping companies, cruises, hotels, leasing
					companies. of cars, etc.
				</p>

				<p>
					40. Despite the close communication established by the
					provider with the service providers, there is the
					possibility that the validity of some prices has expired or
					comes from information erroneously provided by said
					providers with which our database is fed. However, this, the
					provider takes the precaution of verifying the rates that
					apply with each reservation.
				</p>

				<p>
					41. In the event that the correct price is less than the
					quoted amount, the supplier will apply the lower amount. In
					the event that the correct price is greater than the quoted
					amount, the provider will contact you in a timely manner,
					informing you of the correct amount or registering the
					cancellation of the reservation at your request for not
					agreeing with the price increase. , expressly separating the
					provider and its service providers from any liability or
					compensation for inconveniences caused by said cancellation.
				</p>

				<p>DISCLAIMER OF RESPONSIBILITIES</p>

				<p>
					42. The provider acts as an online agency, provides services
					as an intermediary reservation agent between you and the
					Provider or direct Providers of the tourist services
					promoted on this Site. The supplier establishes commercial
					negotiations within its own quality and service standards,
					such as, but not limited to: hotel accommodation, land, sea
					or air transportation and other related tourist activities
					such as tours and excursions, choosing only the most
					qualified suppliers and the most prestigious in the market.
				</p>

				<p>
					43. Since the legal nature of the provider is to act solely
					and exclusively as INTERMEDIARY and not as a direct provider
					of tourist services, the provider does not assume or will
					assume any responsibility generated from any relationship
					before you and the End Providers of Services already in
					turn, you release the provider from any responsibility for
					any failure or lack of compliance by the Provider or direct
					providers of tourist services, including without limitation
					any failure or compliance by the airlines, hotels, temporary
					accommodation providers, shipping companies and all kinds of
					boats, water sports providers, car rental agencies,
					transportation agencies, tour operators, dive instructors,
					snorkel instructors, golf instructors, swim with dolphins
					instructors, fishing instructors, providers of adventure
					sports and extreme sports, water parks, eco parks logical,
					and in general on any failure or lack of compliance by all
					those tourist services that are provided directly by one or
					more Suppliers and not directly by the supplier.
				</p>

				<p>
					44. However, the provider may, at its free discretion,
					provide you with contact information and / or address of the
					service provider in order for you to exercise the respective
					claim or means of claim that you consider necessary.
				</p>

				<p>
					45. The provider does not offer, nor will offer any
					guarantee on the services provided directly by the service
					providers, nor does it guarantee their financial position or
					any reimbursement to you caused by any loss experienced as a
					result of the financial condition of said service. Provider,
					including without limitation, the financial insolvency, or
					Bankruptcy in which said Provider is declared.
				</p>

				<p>
					46. In the event that the service provider fails to comply
					with any of its services, for which the payment has already
					been made, the sole recourse for reimbursement must be
					processed directly with the Provider that failed to provide
					its services. , or may request insurance coverage if
					applicable or before any other third party, unless the
					previous failure is caused directly by the provider.
				</p>

				<p>
					47. The provider does not assume responsibility before you
					and you release the provider from all responsibility for
					acts, omissions or any type of complaint or dissatisfaction
					derived from the services provided by the direct providers
					of services on the Site announced, by virtue of the fact
					that the supplier has no control or legal relationship over
					its personnel, equipment, operation or property.
				</p>

				<p>
					48. In those situations in which the direct provider fails
					to provide its services, you may try any recourse allowed by
					applicable laws against the Provider. The provider
					guarantees that it has high service standards and will take
					special care in the selection of service providers to avoid
					failure at all times.
				</p>

				<p>
					49. The supplier will not be liable and you release the
					supplier from all responsibility regarding:
				</p>

				<p>
					a. The veracity of the photographs displayed on your Site
					since they are only representative and do not guarantee that
					upon arrival, everything will be exactly the same as it is
					appreciated in them and on the Site described;
				</p>

				<p>
					b. Descriptions of travel services are updated by the
					provider to the best of their knowledge, but they do not
					guarantee that everything will be exactly the same upon
					arrival;
				</p>

				<p>
					c. Any type of failure by you to obtain the documentation
					required for your trip such as, but not limited to,
					passports, visas, certificates etc.
				</p>

				<p>
					d. Any type of failure on your part to follow the travel
					instructions including, but not limited to, airport
					departure times, time and date of entry and exit at hotels,
					coupon exchange policies etc;
				</p>

				<p>
					e. Due to lack of financial conditions on the part of the
					Provider to provide the service.
				</p>

				<p>
					f. Regarding the terms and conditions and / or policies of
					direct service providers. The provider will not assume any
					responsibility and you release the provider from all
					liability, as well as from any claim, cost, expense or loss
					that you may suffer including any personal injury or injury
					to third parties, accidents or death, damage to personal
					belongings, loss of fun, anger, disappointment, anguish or
					frustration, whether mental or physical, provided they are
					the result of:
				</p>

				<p>
					Any failure or fault on the part of the Direct Provider in
					providing the service.
				</p>
				<p>
					i. Any failure or lack on your part at the time of enjoying
					the contracted services.
				</p>
				<p>
					ii. Any failure or failure of you to observe or comply with
					the terms and conditions, policies, instructions,
					recommendations, security measures, etc. of the final
					Service Providers.
				</p>
				<p>
					iii. Cases of force majeure or fortuitous cases such as:
					strikes, delays, earthquakes, armed conflicts, hurricanes,
					snowfall, etc. (that are not attributable to the provider),
				</p>
				<p>
					iv. Acts or omissions of any person other than the provider.
				</p>
				<p>
					v. Illness, theft, labor disputes, mechanical failures,
					quarantine, government actions, weather, or any other cause
					beyond the direct control of the supplier.
				</p>
				<p>
					vi. At the insistence of you to carry out any type of trip,
					tourist activity, take a tour, excursion, take any type of
					air, land, sea and any type of transportation, under
					precarious health conditions or that are not optimal for the
					development of such activities, as well as the development
					by you of any type of extreme sports or activities that may
					involve any danger and that were carried out under your full
					knowledge, or when you suffer from any type of disease,
					medical condition, disability, any type of allergy, Find
					under medication or medical prescription, indications of
					rest and / or all those medical conditions that at your own
					risk were omitted and that could result in a deterioration
					to your health at the time of carrying out said activities
					with awareness of cause, under such occasions always they
					will consider were made at your own risk, releasing the
					provider from any complaint, demand gives, complaint,
					disagreement, reward, indemnification, compensation,
					courtesy, etc., derived from any incident caused during the
					development of any service or activity and any future event
					derived from the initial incident for any of these causes.
				</p>
				<p>
					vii. Any other event that is not under the direct control of
					the provider. You will be responsible for verifying that the
					final Provider has special services such as access,
					amenities and services for people with disabilities,
					physical or of any other type, which it is suggested that
					you do so prior to making your reservation. You will be
					responsible for reading and abiding by the terms and
					conditions and / or policies of the final Service Providers.
				</p>

				<p>
					50. Full reimbursement will not be applicable in situations
					in which the trip has to be canceled, interrupted and / or
					postponed by the supplier for reasons beyond its control
					(causes of force majeure, such as, but not limited to,
					weather, hurricane, earthquakes, acts of terrorism, etc.)
					and in which the contractual obligations of the provider
					with its service providers do not allow it to obtain
					reimbursement of the amount paid or to be paid to the
					Provider on behalf of the client.
				</p>

				<p>
					51. In no case will the supplier be liable to the Passengers
					for indirect damages or loss of profits alleged by them as
					long as it does not involve events in which the officials,
					employees or authorities of the supplier have not
					participated in them.
				</p>

				<p>TERMINATION OF THE CONTRACT</p>

				<p>
					52. The provider may terminate these Terms and Conditions
					and / or any of its services at any time without the need to
					notify or notify such termination, for any cause or reason,
					including for any inappropriate use of this site or its
					breach of these Terms and Conditions.
				</p>

				<p>
					53. However, such termination will not affect any right or
					obligation contracted by the provider to you once a
					reservation has been confirmed.
				</p>

				<p>
					54. The supplier reserves the right to make any passenger
					abandon a Travel and / or Tour Plan at any point in the
					same, whose conduct, way of acting, state of health, or
					other serious reason that in the opinion of the supplier
					causes danger or causes inconvenience to the remaining
					travelers or may spoil the success of the excursion or its
					normal development, or may cause damage to third parties.
				</p>

				<p>ASSIGNMENT OF SERVICES</p>

				<p>
					55. You may not assign, subcontract, or delegate your
					rights, obligations or duties contracted here.
				</p>

				<p>APPLICABLE LEGISLATION</p>

				<p>
					56. These Terms and Conditions will be governed by the laws
					of the state of Quintana Roo.
				</p>

				<p>
					57. You declare that you are aware of the scope of this
					Contract, whose Terms and Conditions are the faithful
					expression of your will and are submitted in the event of
					non-compliance or interpretation to the jurisdiction of the
					Federal Consumer Prosecutor's Office, administratively, as
					an instance conciliatory, to resolve the differences that
					may arise and if they subsist, you agree to submit to the
					jurisdiction of the Laws and Courts of Cancun, Quintana Roo,
					under their exclusive jurisdiction, renouncing in turn any
					other jurisdiction that By reason of their present or future
					addresses or by the location of their assets or by their
					nationality it may correspond.
				</p>

				<p>
					58. To the extent permitted by applicable law, no complaint,
					claim or cause of action that is related to your access or
					use of this Site must be submitted after the expiration of
					one (1) year following the date on which such complaint,
					claim or action has been filed or the purchase has been
					completed.
				</p>
			</v-container>

			<v-container class="d-flex justify-center">
				<v-btn
					rounded
					color="primary"
					class="font-weight-bold"
					@click="accept"
					>{{ $t("global.btnAccept") }}</v-btn
				>
			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>
import BtnClose from "@/components/btn/BtnClose";
export default {
	components: {
		BtnClose
	},
	props: {
		value: Boolean
	},
	methods: {
		close() {
			this.$emit("input", false);
		},
		accept() {
			this.$emit("accept");
			this.close();
		},
		t(key, options = {}) {
			return this.$t(`info.help.${key}`, options);
		}
	}
};
</script>

<style></style>
