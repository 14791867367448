<template>
	<v-dialog max-width="300px" :value="value" @input="close">
		<v-card
			min-height="250px"
			class=" d-flex flex-column justify-space-around  align-center"
		>
			<BtnClose @click="close" />

			<v-card-title class="mt-2">
				<v-icon
					:color="icon.color"
					class="material-icons-outlined"
					size="60"
					>{{ icon.icon }}</v-icon
				>
			</v-card-title>

			<v-card-subtitle
				class="font-weight-black mt-3 mx-12 flex text-center "
			>
				{{ title }}
			</v-card-subtitle>

			<v-card-text class="font-weight-medium mx-12 flex text-center">
				<slot />
			</v-card-text>
			<slot name="bottom" />
		</v-card>
	</v-dialog>
</template>

<script>
import BtnClose from "@/components/btn/BtnClose";
export default {
	components: {
		BtnClose
	},
	props: {
		value: Boolean,
		title: String,
		type: {
			type: String,
			default: "success"
		}
	},
	computed: {
		icon: vm => {
			switch (vm.type) {
				case "success":
					return { color: "#46CF39", icon: "check_circle" };
				case "error":
					return { color: "#ED3B3B", icon: "cancel" };
				case "alert":
					return { color: "primary", icon: "info" };
				case "confirm":
					return { color: "primary", icon: "verified_user" };
				default:
					return { color: "#46CF39", icon: "check_circle" };
			}
		}
	},
	methods: {
		close() {
			this.$emit("input", false);
			this.$emit("close");
		}
	}
};
</script>
